import { CssBaseline } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const MuiThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Roboto', '"Noto Sans JP"', 'sans-serif'].join(','),
    },
    palette: {
      primary: { main: '#fbfb99' },
      secondary: { main: indigo.A400 },
    },
    components: {
      MuiLink: {
        defaultProps: { color: 'secondary', underline: 'hover' },
      },
      MuiButton: {
        defaultProps: { color: 'inherit' },
      },
      MuiTextField: {
        defaultProps: { variant: 'outlined', color: 'secondary' },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
